<template>
  <div class="row d-flex justify-content-center">
    <div v-if="message" :class="'col-12 alert alert-' + type">
      {{ message }}
    </div>

    <div v-if="messages.length" :class="'col-12 alert alert-' + type">
      <span v-for="(message, index) in messages" :key="index"
        >{{ message }}<br
      /></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "VAlert",

  props: {
    type: {
      type: String,
      default: "info",
    },
    message: {
      type: String,
      default: null,
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
