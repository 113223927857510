<template>
  <footer class="default-footer text-center">
    {{
      $t(appName.toLowerCase() + ".copyright").replace(
        ":yearParam",
        new Date().getFullYear()
      )
    }}
  </footer>
</template>

<script>
export default {
  name: "VFooter",

  props: {
    appName: {
      type: String,
      isRequired: true,
    },
  },
};
</script>
