import Vue from "vue";
import VueI18n from "vue-i18n";
import {
  __VUE_APP_I18N_LOCALE__,
  __VUE_APP_I18N_FALLBACK_LOCALE__,
} from "@/consts";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: __VUE_APP_I18N_LOCALE__ || "en",
  fallbackLocale: __VUE_APP_I18N_FALLBACK_LOCALE__ || "en",
  messages: {},
});

export async function loadMessages(locale, languages) {
  if (
    languages &&
    locale &&
    Object.keys(i18n.getLocaleMessage(locale)).length === 0
  ) {
    i18n.setLocaleMessage(locale, languages[locale]);
  }

  if (i18n.locale !== locale) i18n.locale = locale;
}

export default i18n;
