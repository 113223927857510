<template>
  <div class="col-3 text-center">
    <button
      class="btn step-button"
      v-bind:class="{
        disabled: !isActive && !isCompleted,
        'btn-outline-primary': !isCompleted,
        'btn-outline-success': isCompleted,
        'step-button-success': isCompleted,
        'mr-lg-2': !isRight,
        'ml-lg-2': isRight,
      }"
      @click="handleClick"
    >
      <span v-show="!isCompleted">
        {{ id }}
      </span>

      <span v-show="isCompleted">
        <span class="fas fa-check m-0 p-0"></span>
      </span>
    </button>

    <span
      v-bind:class="{
        'text-muted': !isActive,
        'cursor-pointer': isCompleted,
      }"
      class="step-text d-none d-lg-inline"
      @click="handleClick"
    >
      {{ $t("rma.steps." + name) }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Step",
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
    isRight: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleClick(e) {
      this.$emit("onClick", e);
    },
  },
};
</script>
