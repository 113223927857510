export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_LANGUAGES_SUCCESS = "SET_LANGUAGES_SUCCESS";
export const SET_LANGUAGES_ERROR = "SET_LANGUAGES_ERROR";
export const CLEAR_LANGUAGES_STATE = "CLEAR_LANGUAGES_STATE";

export const SET_CURRENT_LANG = "SET_CURRENT_LANG";
export const SET_CURRENT_LANG_DIRECTION = "SET_CURRENT_LANG_DIRECTION";
export const SET_ERROR = "SET_ERROR";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const SET_STEP_ACTIVE = "SET_STEP_ACTIVE";
export const SET_STEP_COMPLETED = "SET_STEP_COMPLETED";
export const CLEAR_STEPS = "CLEAR_STEPS";

export const SET_INVOICE = "SET_INVOICE";
export const SET_INVOICE_SUCCESS = "SET_INVOICE_SUCCESS";
export const SET_INVOICE_ERROR = "SET_INVOICE_ERROR";
export const CLEAR_INVOICE = "CLEAR_INVOICE";

export const ADD_ORDER = "ADD_ORDER";
export const REMOVE_ORDER = "REMOVE_ORDER";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";

export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_FORM_DATA_SUCCESS = "SET_FORM_DATA_SUCCESS";
export const SET_FORM_DATA_ERROR = "SET_FORM_DATA_ERROR";
export const CLEAR_FORM_DATA = "CLEAR_FORM_DATA";
