const appName =
  window.location.href.indexOf("eptimo") !== -1 ? "EPTIMO" : "SENETIC";

const url = process.env["VUE_APP_" + appName + "_RMA_API_URL"];

// --- API ---
export const __BASE_API_URL__ = url || "https://api.senetic.com/rmatest/1.0/";

export const __TRANSLATION_API_URL__ = `${__BASE_API_URL__}translation/getAllTranslations`;
export const __TMP_API_URL__ = `${__BASE_API_URL__}tmp`;

export const __FIND_INVOICE_API_URL__ = `${__BASE_API_URL__}findInvoice`;

export const __RMA_FORM_API_URL__ = `${__BASE_API_URL__}saveRmaApplication`;

// --- LANGUAGE ---
export const __VUE_APP_I18N_LOCALE__ = "en";

export const __VUE_APP_I18N_FALLBACK_LOCALE__ = "en";
