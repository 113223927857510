<template>
  <div class="row d-flex justify-content-center steps-row">
    <template v-for="(step, index) in steps">
      <v-step
        :isRight="isRight"
        :id="step.id"
        :name="step.path"
        :isActive="step.isActive"
        :isCompleted="step.isCompleted"
        :key="`${index}-step`"
        @onClick="(e) => handleClick(e, step)"
      />

      <div
        v-if="steps.length - 1 !== index"
        class="col-1"
        :key="`${index}-line`"
      >
        <hr />
      </div>
    </template>
  </div>
</template>

<script>
import VStep from "./VStep.vue";

export default {
  name: "Steps",

  components: {
    VStep,
  },

  props: {
    steps: {
      type: Array,
      required: true,
    },
    isRight: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleClick(e, step) {
      this.$emit("onClick", e, step);
    },
  },
};
</script>
