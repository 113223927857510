import { postcodeValidator } from "postcode-validator";

export const postalCode = (key) => (value) => {
  try {
    return postcodeValidator(value, localStorage.getItem(key)?.toUpperCase());
  } catch (err) {
    console.error(err);
    return postcodeValidator(value, getCookie("locale")?.toUpperCase() || "EN");
  }
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return parts.length === 2 ? parts.pop().split(";").shift() : undefined;
};

export const setCookie = (name, value, days = 365) => {
  let expires = "";

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }

  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const sortObject = (prop) => {
  return function (objA, objB) {
    const a = objA[prop];
    const b = objB[prop];

    return a > b ? 1 : a < b ? -1 : 0;
  };
};

export const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
