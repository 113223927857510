import axios from "axios";
import { __FIND_INVOICE_API_URL__ } from "@/consts";
import * as types from "@/store/mutation-types";

export const state = {
  data: null,
  loading: false,
  error: null,
};

export const mutations = {
  [types.SET_INVOICE](state) {
    state.loading = true;
  },

  [types.SET_INVOICE_SUCCESS](state, payload) {
    state.data = payload;
    state.loading = false;
  },

  [types.SET_INVOICE_ERROR](state, payload) {
    state.error = payload;
    state.loading = false;
    state.data = null;
  },

  [types.CLEAR_INVOICE](state) {
    state.data = null;
    state.loading = false;
    state.error = null;
  },
};

export const actions = {
  findInvoice({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("app/CLEAR_ERROR", null, { root: true });
      commit("SET_INVOICE");

      axios
        .post(__FIND_INVOICE_API_URL__, payload)
        .then((response) => {
          if (response?.data) {
            commit("SET_INVOICE_SUCCESS", {
              ...response.data,
              clientNumber: payload.clientNumber,
            });
            commit("steps/SET_STEP_ACTIVE", 2, { root: true });
            commit("steps/SET_STEP_COMPLETED", 1, { root: true });

            resolve();
            return;
          }

          commit(
            "SET_INVOICE_ERROR",
            "Error: please contact with administrator."
          );
          commit("app/SET_ERROR", true, { root: true });
          commit(
            "app/SET_ERROR_MESSAGE",
            "Error: please contact with administrator.",
            { root: true }
          );

          reject();
        })
        .catch((err) => {
          console.error(err);

          commit("SET_INVOICE_ERROR", err);
          commit("app/SET_ERROR", true, { root: true });
          commit("app/SET_ERROR_MESSAGE", err, { root: true });

          reject();
        });
    });
  },
};

export const getters = {
  getInvoice: (state) => state.data,

  getProducts: (state) => state.data?.lines || [],

  getLoading: (state) => state.loading,

  getError: (state) => state.error,

  getFilteredProducts: (state, _, rootState) => {
    const invoiceLines = state.data?.lines;

    if (!Array.isArray(invoiceLines) || !invoiceLines.length) {
      return [];
    }

    const products = rootState.products.lines;

    if (!products.length) {
      return invoiceLines;
    }

    const result = [];

    for (let i = 0; i < invoiceLines.length; i++) {
      const line = invoiceLines[i];
      const existedProduct = products.find(
        ({ partNumber }) => partNumber === line.partNumber
      );

      if (existedProduct && Array.isArray(line?.serials)) {
        const filteredSerials = line.serials.filter(
          (serial) => !existedProduct.serialNumber.includes(serial)
        );

        if (filteredSerials.length) {
          result.push({ ...line, serials: filteredSerials });
        }
      } else {
        result.push(line);
      }
    }

    return result;
  },
};

export default { namespaced: true, state, mutations, actions, getters };
