import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app";
import steps from "./modules/steps";
import translation from "./modules/translation";
import invoice from "./modules/invoice";
import products from "./modules/products";
import rmaForm from "./modules/rmaForm";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    steps,
    translation,
    invoice,
    products,
    rmaForm,
  },
});

export default store;
