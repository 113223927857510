<template>
  <b-dropdown
    :text="dropdownText"
    class="m-md-2 locale-dropdown"
    variant="outline-info"
    :right="isRight"
  >
    <b-dropdown-item
      v-for="(lang, index) in languages"
      :key="index"
      @click.prevent="goToLang(lang.langCode)"
    >
      {{ lang.langName }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: "LocaleDropdown",

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    languages: {
      type: Array,
      default: () => [],
    },
    currentLang: {
      type: String,
      default: "",
    },
  },

  computed: {
    dropdownText() {
      return this.languages.find((lang) => lang.langCode === this.currentLang)
        ?.langName;
    },
  },

  methods: {
    goToLang(lang) {
      this.$emit("onChangeLang", lang);
    },
  },
};
</script>
