import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";

Vue.use(VueRouter);

async function beforeEach(to, from, next) {
  await store.dispatch("translation/fetchTranslations");
  await store.dispatch("app/getLocale", to.params.lang);

  if (to.name === "RmaForm") {
    if (store.state.invoice.data) await next();
    else await next("/");

    return;
  }

  if (to.name === "Summary") {
    if (store.state.invoice.data) await next();
    else await next("/");

    return;
  }

  await next();
}

function createRouter() {
  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach(beforeEach);

  return router;
}

const router = createRouter();

export default router;
