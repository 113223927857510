import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import Vuelidate from "vuelidate";
import vSelect from "vue-select";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import i18n from "./i18n";

import "@/assets/styles/Styles.scss";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);

Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
