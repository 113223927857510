import * as types from "@/store/mutation-types";

export const state = {
  lines: [],
};

export const mutations = {
  [types.ADD_ORDER](state, payload) {
    state.lines = payload;
  },

  [types.REMOVE_ORDER](state) {
    state.lines = [];
  },

  [types.ADD_PRODUCT](state, payload) {
    const product = state.lines.find(({ id }) => id === payload?.id);
    const lines = product
      ? state.lines.filter(({ id }) => id !== payload?.id)
      : state.lines;

    state.lines = [payload, ...lines];
  },

  [types.REMOVE_PRODUCT](state, payload) {
    state.lines = state.lines.filter(({ id }) => id !== payload?.id);
  },
};

export const actions = {};

export const getters = {
  getProducts: (state) => state.lines,
};

export default { namespaced: true, state, mutations, actions, getters };
