var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:{
    'text-left': !_vm.isRight,
    'text-right': _vm.isRight,
  },attrs:{"role":"group"}},[_c('div',[(_vm.label && !this.$slots.label)?_c('label',{class:{ 'text-danger': _vm.state === false },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.isRequired)?_c('span',{staticClass:"text-danger pl-1"},[_vm._v("*")]):_vm._e()]):_vm._e(),_vm._t("label"),(_vm.popoverText || _vm.$slots['popover'])?_c('popper',{attrs:{"trigger":"hover","options":{
        placement: _vm.isRight ? 'right' : 'left',
      }}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"img-fluid-container"},[_vm._t("popover")],2),_vm._v(" "+_vm._s(_vm.popoverText)+" ")]),_c('span',{staticClass:"far fa-question-circle cursor-pointer d-inline-block",class:{
          'mx-1': !_vm.isRight,
          'mr-1 ml-0': _vm.isRight,
        },attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],2),_c('b-form-input',{attrs:{"id":_vm.id,"type":_vm.type,"min":_vm.min,"max":_vm.max,"state":_vm.state,"aria-describedby":(_vm.id + "-live-help " + _vm.id + "-feedback"),"placeholder":_vm.placeholder,"disabled":_vm.disabled},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}}),(_vm.error && !this.$slots.error)?_c('b-form-invalid-feedback',{attrs:{"id":(_vm.id + "-feedback")}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_vm._t("error")],2)}
var staticRenderFns = []

export { render, staticRenderFns }