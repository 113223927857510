<template>
  <div class="row justify-content-md-center">
    <div class="col-md-8 col-lg-6">
      <v-form-card
        id="search-invoice"
        :isRight="isRightLangDirection"
        :title="title"
        :error="getErrorMessage"
      >
        <div class="alert alert-info">
          <div
            class="mr-2 float-left"
            v-bind:class="{
              'mr-2': !isRightLangDirection,
              'ml-2': isRightLangDirection,
            }"
          >
            <svg
              width="14"
              height="14"
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="info-circle"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="svg-inline--fa fa-info-circle fa-w-16 fa-3x"
            >
              <path
                fill="currentColor"
                d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"
                class="far fa-info-circle"
              ></path>
            </svg>
          </div>

          <div class="d-table">
            {{ $t("label.search.invoice.info") }}
          </div>
        </div>

        <p class="mb-4">
          {{ $t("label.search.invoice.form.link") }}
        </p>

        <v-invoice-form
          :locale="getCurrentLang"
          :isRight="isRightLangDirection"
          :isLoading="getLoading"
          :appName="appName"
          :defaultInvoiceNumber="invoiceNumber"
          :defaultClientNumber="clientNumber"
          :defaultOrderNumber="orderNumber"
          @onSubmit="handleSubmit"
        />
      </v-form-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VFormCard from "@/components/VFormCard";
import VInvoiceForm from "./VInvoiceForm";

export default {
  name: "SearchInvoice",

  components: {
    VFormCard,
    VInvoiceForm,
  },

  data() {
    return {
      invoiceNumber: null,
      clientNumber: null,
      orderNumber: null,
    };
  },

  methods: {
    ...mapActions("invoice", ["findInvoice"]),

    handleSubmit(payload) {
      this.findInvoice(payload).then(() => {
        this.$router.push({
          name: this.getActiveStep.name,
        });
      });
    },

    getQueryParams() {
      try {
        const { invoiceNumber, clientNumber, orderNumber } =
          this.$router.history.current.query;
        this.invoiceNumber = invoiceNumber;
        this.clientNumber = clientNumber;
        this.orderNumber = orderNumber;
      } catch (err) {
        console.error("There are no query parmas ", err);
      }
    },
  },

  computed: {
    ...mapGetters("app", [
      "isRightLangDirection",
      "getCurrentLang",
      "getAppName",
      "getCurrentLang",
    ]),
    ...mapGetters("steps", ["getActiveStep"]),
    ...mapGetters("invoice", ["getLoading", "getError"]),

    title() {
      return this.$t("label.search.invoice.search-title");
    },

    appName() {
      return this.getAppName.toLowerCase();
    },

    getErrorMessage() {
      const status = this.getError?.response?.status;

      return status
        ? this.$t(`${this.getAppName.toLowerCase()}.request.error.${status}`)
        : null;
    },
  },

  mounted() {
    this.getQueryParams();
  },
};
</script>
