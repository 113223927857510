var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-3 text-center"},[_c('button',{staticClass:"btn step-button",class:{
      disabled: !_vm.isActive && !_vm.isCompleted,
      'btn-outline-primary': !_vm.isCompleted,
      'btn-outline-success': _vm.isCompleted,
      'step-button-success': _vm.isCompleted,
      'mr-lg-2': !_vm.isRight,
      'ml-lg-2': _vm.isRight,
    },on:{"click":_vm.handleClick}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCompleted),expression:"!isCompleted"}]},[_vm._v(" "+_vm._s(_vm.id)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCompleted),expression:"isCompleted"}]},[_c('span',{staticClass:"fas fa-check m-0 p-0"})])]),_c('span',{staticClass:"step-text d-none d-lg-inline",class:{
      'text-muted': !_vm.isActive,
      'cursor-pointer': _vm.isCompleted,
    },on:{"click":_vm.handleClick}},[_vm._v(" "+_vm._s(_vm.$t("rma.steps." + _vm.name))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }