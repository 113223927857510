<template>
  <div class="header-container">
    <div class="container">
      <div class="row">
        <div
          class="
            col-12 col-md-3
            d-flex
            justify-content-center justify-content-md-start
            mt-3
            mb-3
          "
        >
          <img
            v-if="appName.toLowerCase() === 'senetic'"
            src="@/assets/images/senetic-logo.png"
            alt="Senetic logo"
          />
          <img
            v-else
            src="@/assets/images/eptimo-white-logo.png"
            alt="Eptimo logo"
          />
        </div>

        <div
          class="
            col-6 col-md-6
            justify-content-start justify-content-md-center
            page-title
            float-left
            mt-3
            mb-3
          "
        >
          <h1 class="page-title">
            {{ $t("layout.default.page.title") }}
          </h1>
        </div>

        <div class="col-6 col-md-3 mt-3 mb-3 justify-content-end">
          <span class="text-white pr-1">{{
            $t("layout.default.locale.dropdown.label")
          }}</span>

          <v-locale-dropdown
            :isRight="isRight"
            :languages="languages"
            :currentLang="currentLang"
            @onChangeLang="handleChangeLang"
          />

          <div v-show="loading" class="locale-dropdown-loading-container">
            <span class="fas fa-spinner fa-pulse m-0"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VLocaleDropdown from "@/components/VLocaleDropdown";

export default {
  name: "Header",

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    appName: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    languages: {
      type: Array,
      default: () => [],
    },
    currentLang: {
      type: String,
      default: "",
    },
  },

  methods: {
    handleChangeLang(lang) {
      this.$emit("onChangeLang", lang);
    },
  },

  components: {
    VLocaleDropdown,
  },
};
</script>
