import * as types from "@/store/mutation-types";

const initialState = [
  {
    id: 1,
    name: "SearchInvoice",
    path: "search-invoice",
    isActive: true,
    isCompleted: false,
  },
  {
    id: 2,
    name: "RmaForm",
    path: "rma-form",
    isActive: false,
    isCompleted: false,
  },
  {
    id: 3,
    name: "Summary",
    path: "summary",
    isActive: false,
    isCompleted: false,
  },
];

export const state = {
  steps: initialState,
};

export const mutations = {
  [types.SET_STEP_ACTIVE](state, id) {
    state.steps = state.steps.map((step) =>
      step.id === id
        ? { ...step, isActive: true }
        : { ...step, isActive: false }
    );
  },

  [types.SET_STEP_COMPLETED](state, id) {
    state.steps = state.steps.map((step) =>
      step.id === id ? { ...step, isCompleted: true } : step
    );
  },

  [types.CLEAR_STEPS](state) {
    state.steps = initialState;
  },
};

export const actions = {};

export const getters = {
  getSteps: (state) => state.steps,

  getActiveStep: (state) => state.steps.find((step) => step.isActive === true),
};

export default { namespaced: true, state, mutations, actions, getters };
