import axios from "axios";
import * as types from "@/store/mutation-types";
import { scrollTop } from "@/utils";
import { __RMA_FORM_API_URL__ } from "@/consts";

export const state = {
  data: null,
  error: null,
  loading: false,
};

export const mutations = {
  [types.SET_FORM_DATA](state) {
    state.loading = true;
  },

  [types.SET_FORM_DATA_SUCCESS](state, payload) {
    state.data = { ...state.data, ...payload };
    state.loading = false;
  },

  [types.SET_FORM_DATA_ERROR](state, payload) {
    state.error = payload;
    state.data = null;
    state.loading = false;
  },

  [types.CLEAR_FORM_DATA](state) {
    state.data = null;
    state.loading = false;
    state.error = null;
  },
};

export const actions = {
  getBase64(_, payload) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(payload);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },

  async getFiles({ dispatch }, payload) {
    const promises = payload.map(async (file) => {
      const base64 = await dispatch("getBase64", file);
      return { fileName: file.name, fileContent: base64 };
    });
    const files = await Promise.all(promises);

    return files;
  },

  async getProducts({ dispatch }, payload) {
    const promises = payload.map(async (item) => {
      const files = await dispatch("getFiles", item.files);
      return { ...item, files };
    });
    const products = await Promise.all(promises);

    return products || [];
  },

  sendRmaFormRequest({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const {
        app: { appName, currentLang },
        invoice: {
          data: { invoiceNumber, clientNumber, orderNumber },
        },
      } = rootState;

      const data = {
        ...payload,
        contact: {
          ...payload.contact,
          locale: currentLang,
        },
        invoiceNumber,
        clientNumber,
        orderNumber,
        source: appName.toLowerCase() === "eptimo" ? 2 : 1,
      };

      commit("app/CLEAR_ERROR", null, { root: true });

      return axios
        .post(__RMA_FORM_API_URL__, data)
        .then((response) => {
          scrollTop();

          if (response?.data) {
            commit("SET_FORM_DATA_SUCCESS", {
              ...data,
              rmaApplicationId: response.data[0]?.rmaApplicationId,
            });
            commit("steps/SET_STEP_ACTIVE", 3, { root: true });
            commit("steps/SET_STEP_COMPLETED", 2, { root: true });

            resolve();

            return;
          }

          commit(
            "SET_FORM_DATA_ERROR",
            "Error: please contact with administrator."
          );

          commit("app/SET_ERROR", true, { root: true });
          commit(
            "app/SET_ERROR_MESSAGE",
            "Error: please contact with administrator.",
            { root: true }
          );

          reject();
        })
        .catch((err) => {
          const messageCopy = `${appName.toLowerCase()}.request.error`;

          let message = err;

          switch (err?.response?.status) {
            case 400:
              message = `${messageCopy}.400`;
              break;
            case 404:
              message = `${messageCopy}.404`;
              break;
            default:
              message = `${messageCopy}.500`;
              break;
          }

          commit("SET_FORM_DATA_ERROR", message);
          commit("app/SET_ERROR", true, { root: true });
          commit("app/SET_ERROR_MESSAGE", message, { root: true });

          scrollTop();

          console.error(err);

          reject();
        });
    });
  },

  async sendRmaForm({ dispatch, commit }, payload) {
    await commit("SET_FORM_DATA");
    const products = await dispatch("getProducts", payload.lines);
    const form = await dispatch("sendRmaFormRequest", {
      ...payload,
      lines: products,
    });

    return form;
    // try {

    // } catch (err) {
    //   commit("SET_FORM_DATA_ERROR", err);
    //   commit("app/SET_ERROR", true, { root: true });
    //   commit("app/SET_ERROR_MESSAGE", err, { root: true });

    //   scrollTop();

    //   console.error(err);
    // }
  },
};

export const getters = {
  getInvoice: (state) => ({
    invoiceNumber: state.data?.invoiceNumber || null,
    clientNumber: state.data?.clientNumber || null,
    orderNumber: state.data?.orderNumber || null,
  }),

  getBankDetails: (state) => state.data?.bankDetails || {},

  getComplaintType: (state) =>
    state.data?.additionalInformations?.complaintType || null,

  getComplaintMethod: (state) =>
    state.data?.additionalInformations?.preferredRealisation || null,

  getContactData: (state) => state.data?.contact || null,

  getPickupAddress: (state) =>
    state.data?.additionalInformations?.pickupAddress || null,

  getReturnAddress: (state) => state.data?.returnAddress || null,

  getRmaApplicationId: (state) => state.data?.rmaApplicationId || null,

  getLines: (state) => state.data?.lines || [],

  getSuccessData: (state) => (state.data?.rmaApplicationId ? state.data : []),

  getError: (state) => state.error,

  getLoading: (state) => state.loading,
};

export default { namespaced: true, state, mutations, actions, getters };
