import { getCookie, setCookie } from "@/utils";
import { loadMessages } from "@/i18n";
import * as types from "@/store/mutation-types";

export const state = {
  currentLang: getCookie("locale") || "en",
  currentLangDirection: "ltr",
  appName: window.location.href.indexOf("eptimo") !== -1 ? "Eptimo" : "Senetic",
  error: false,
  errorMessage: null,
};

export const mutations = {
  [types.SET_CURRENT_LANG](state, payload) {
    state.currentLang = payload;
  },

  [types.SET_CURRENT_LANG_DIRECTION](state, payload) {
    state.currentLangDirection = payload;
  },

  [types.SET_ERROR](state, payload) {
    state.error = payload;
  },

  [types.SET_ERROR_MESSAGE](state, payload) {
    state.errorMessage = payload;
  },

  [types.CLEAR_ERROR](state) {
    state.error = null;
    state.errorMessage = null;
  },
};

export const actions = {
  getLocale({ state, commit, rootState }, payload) {
    return new Promise((resolve) => {
      const language = payload || state.currentLang;

      const direction =
        rootState.translation.data[language]?.language_direction ||
        state.currentLangDirection;
      commit("SET_CURRENT_LANG_DIRECTION", direction);
      document.getElementsByTagName("html")[0].setAttribute("dir", direction);

      commit("SET_CURRENT_LANG", language);
      setCookie("locale", language);
      loadMessages(language, rootState.translation.data);

      resolve();
    });
  },

  resetStoreData({ commit, dispatch }) {
    commit("CLEAR_ERROR");
    commit("translation/CLEAR_LANGUAGES_STATE", null, { root: true });
    commit("steps/CLEAR_STEPS", null, { root: true });
    commit("rmaForm/CLEAR_FORM_DATA", null, { root: true });
    commit("invoice/CLEAR_INVOICE", null, { root: true });
    commit("products/REMOVE_PRODUCT", null, { root: true });

    dispatch("translation/fetchTranslations", null, { root: true });
  },
};

export const getters = {
  getAppName: (state) => state.appName,

  getCurrentLang: (state) => state.currentLang,

  isRightLangDirection: (state) => state.currentLangDirection === "rtl",

  getError: (state) => state.error,

  getErrorMessage: (state) => state.errorMessage,
};

export default { namespaced: true, state, mutations, actions, getters };
