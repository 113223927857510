import axios from "axios";
import { sortObject } from "@/utils";
import { __TRANSLATION_API_URL__ } from "@/consts";
import * as types from "../mutation-types";

export const state = {
  data: [],
  loading: true,
  error: null,
};

export const mutations = {
  [types.SET_LANGUAGES](state) {
    state.loading = true;
  },

  [types.SET_LANGUAGES_SUCCESS](state, payload) {
    state.data = payload;
    state.loading = false;
  },

  [types.SET_LANGUAGES_ERROR](state, payload) {
    state.error = payload;
    state.loading = false;
    state.data = [];
  },

  [types.CLEAR_LANGUAGES_STATE](state) {
    state.data = [];
    state.loading = true;
    state.error = null;
  },
};

export const actions = {
  fetchTranslations({ state, commit }) {
    return new Promise((resolve) => {
      if (!Array.isArray(state.data)) {
        resolve();
        return;
      }

      commit("SET_LANGUAGES");

      axios
        .get(__TRANSLATION_API_URL__)
        .then((response) => {
          if (response?.data) {
            commit("SET_LANGUAGES_SUCCESS", response.data);
            return;
          }

          commit("app/SET_ERROR", true, { root: true });
          commit(
            "app/SET_ERROR_MESSAGE",
            "Error: please contact with administrator.",
            { root: true }
          );
          commit(
            "SET_LANGUAGES_ERROR",
            "Error: please contact with administrator."
          );
        })
        .catch((err) => {
          console.error(err);

          commit("app/SET_ERROR", true, { root: true });
          commit(
            "app/SET_ERROR_MESSAGE",
            "Error: please contact with administrator.",
            { root: true }
          );
          commit(
            "SET_LANGUAGES_ERROR",
            "Error: please contact with administrator."
          );
        })
        .finally(() => {
          resolve();
        });
    });
  },
};

export const getters = {
  getLanguages: (state) => {
    let langKeys = Object.keys(state.data);
    let langs = [];

    for (let i = 0; i < langKeys.length; i++) {
      langs.push({
        langCode: langKeys[i],
        langName: state.data[langKeys[i]].language,
      });
    }

    return langs.sort(sortObject("langName"));
  },

  getLoading: (state) => state.loading,
};

export default { namespaced: true, state, mutations, actions, getters };
