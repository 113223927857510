<template>
  <section class="card mb-4" :class="{ 'card-not-nested': !isNested }" :id="id">
    <v-alert
      v-if="!!error"
      :message="error"
      type="danger"
      :id="`${id}-request-error`"
    />

    <h5
      v-if="title"
      class="card-title"
      :class="{
        'text-left': !isRight,
        'text-right': isRight,
      }"
    >
      {{ title }} <span v-if="isRequired" class="text-danger pl-1">*</span>
    </h5>

    <div class="card-body">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import VAlert from "@/components/VAlert";

export default {
  name: "VFormCard",

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    isNested: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },

  components: {
    VAlert,
  },
};
</script>
