<template>
  <div class="loader">
    <span class="fas fa-spinner fa-pulse m-0"></span>
  </div>
</template>

<script>
export default {
  name: "VLoader",
};
</script>

<style lang="scss">
.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#f0f0f0, 0.5);
}
</style>
