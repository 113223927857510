<template>
  <b-form @submit="handleSubmit">
    <v-input
      :isRight="isRight"
      id="invoice-number"
      :isRequired="true"
      :value="defaultInvoiceNumber"
      :label="$t('label.search.invoice.vat.number')"
      :state="handleState('invoiceNumber')"
      :error="$t('error.required')"
      @onChange="(val) => handleChange('invoiceNumber', val)"
    >
      <template v-if="locale === 'tr'">
        <img
          v-if="appName === 'senetic'"
          src="@/assets/images/senetic-rma-invoice-no-tr.png"
          alt="invoice-number-image`"
          slot="popover"
        />
        <img
          v-else
          src="@/assets/images/eptimo-rma-invoice-no-tr.png"
          alt="invoice-number-image`"
          slot="popover"
        />
      </template>
      <template v-else>
        <img
          v-if="appName === 'senetic'"
          src="@/assets/images/senetic-rma-invoice-no.png"
          alt="invoice-number-image`"
          slot="popover"
        />
        <img
          v-else
          src="@/assets/images/eptimo-rma-invoice-no.png"
          alt="invoice-number-image`"
          slot="popover"
        />
      </template>
    </v-input>

    <v-input
      :isRight="isRight"
      id="client-number"
      :value="defaultClientNumber"
      :isRequired="true"
      :label="$t('label.search.invoice.client.number')"
      :state="handleState('clientNumber')"
      :error="$t('error.required')"
      @onChange="(val) => handleChange('clientNumber', val)"
    >
      <template v-if="locale === 'tr'">
        <img
          v-if="appName === 'senetic'"
          src="@/assets/images/senetic-rma-user-no-tr.png"
          alt="client-number-image`"
          slot="popover"
        />
        <img
          v-else
          src="@/assets/images/eptimo-rma-user-no-tr.png"
          alt="client-number-image`"
          slot="popover"
        />
      </template>
      <template v-else>
        <img
          v-if="appName === 'senetic'"
          src="@/assets/images/senetic-rma-user-no.png"
          alt="client-number-image`"
          slot="popover"
        />
        <img
          v-else
          src="@/assets/images/eptimo-rma-user-no.png"
          alt="client-number-image`"
          slot="popover"
        />
      </template>
    </v-input>

    <v-input
      :isRight="isRight"
      id="order-number"
      :isRequired="true"
      :value="defaultOrderNumber"
      :label="$t('label.search.invoice.order.number')"
      :state="handleState('orderNumber')"
      :error="$t('error.required')"
      @onChange="(val) => handleChange('orderNumber', val)"
    >
      <template v-if="locale === 'tr'">
        <img
          v-if="appName === 'senetic'"
          src="@/assets/images/senetic-rma-order-no-tr.png"
          alt="order-number-image`"
          slot="popover"
        />
        <img
          v-else
          src="@/assets/images/eptimo-rma-order-no-tr.png"
          alt="order-number-image`"
          slot="popover"
        />
      </template>
      <template v-else>
        <img
          v-if="appName === 'senetic'"
          src="@/assets/images/senetic-rma-order-no.png"
          alt="order-number-image`"
          slot="popover"
        />
        <img
          v-else
          src="@/assets/images/eptimo-rma-order-no.png"
          alt="order-number-image`"
          slot="popover"
        />
      </template>
    </v-input>

    <div class="form-group text-center form-submit-container">
      <b-button variant="success" type="submit" :disabled="isLoading">
        <span v-show="!isLoading">
          {{ $t("label.search.invoice.search") }}
        </span>
        <i class="fas fa-spinner fa-pulse m-0" v-show="isLoading" />
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import VInput from "@/components/VInput";

export default {
  name: "SearchInvoice",

  components: {
    VInput,
  },

  data() {
    return {
      invoiceNumber: null,
      clientNumber: null,
      orderNumber: null,
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    locale: {
      type: String,
      isRequired: true,
    },
    appName: {
      type: String,
      isRequired: true,
    },
    defaultInvoiceNumber: {
      type: String,
      default: null,
    },
    defaultClientNumber: {
      type: String,
      default: null,
    },
    defaultOrderNumber: {
      type: String,
      default: null,
    },
  },

  validations: {
    invoiceNumber: {
      required,
    },
    clientNumber: {
      required,
    },
    orderNumber: {
      required,
    },
  },

  methods: {
    handleChange(name, value) {
      this[name] = value;
      this.$v[name].$touch();
    },

    handleState(name) {
      return !this.$v[name].$error && this.$v[name].$model === null
        ? undefined
        : !this.$v[name].$error;
    },

    handleSubmit(event) {
      event.preventDefault();

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$emit("onSubmit", {
          invoiceNumber: this.invoiceNumber,
          clientNumber: this.clientNumber,
          orderNumber: this.orderNumber,
        });
      }
    },
  },
};
</script>
