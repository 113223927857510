import SearchInvoice from "@/views/SearchInvoice";
import store from "@/store";

const currentLang = store.state.app.currentLang;

const routes = [
  {
    path: "/",
    redirect: `/${currentLang}/search-invoice`,
  },
  {
    path: "/:lang",
    redirect: "/:lang/search-invoice",
  },
  {
    path: "/:lang/search-invoice",
    name: "SearchInvoice",
    component: SearchInvoice,
  },
  {
    path: "/:lang/rma-form",
    name: "RmaForm",
    component: () =>
      import(/* webpackChunkName: "RmaForm" */ "@/views/RmaForm"),
  },
  {
    path: "/:lang/summary",
    name: "Summary",
    component: () =>
      import(/* webpackChunkName: "Summary" */ "@/views/Summary"),
  },
  {
    path: `/${currentLang}/404`,
    name: "Error404",
    component: () =>
      import(/* webpackChunkName: "Error404" */ "@/views/Error404"),
  },
  {
    path: "*",
    redirect: `/${currentLang}/404`,
  },
];

export default routes;
