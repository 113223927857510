<template>
  <div
    id="app"
    class="default-layout"
    :class="{
      'text-left': isRightLangDirection,
      'text-right': isRightLangDirection,
    }"
  >
    <v-loader v-if="showLoading" />

    <template v-if="showContent">
      <v-header
        :isRight="isRightLangDirection"
        :appName="getAppName"
        :loading="getTranslationLoading"
        :languages="getLanguages"
        :currentLang="getCurrentLang"
        @onChangeLang="handleChangeLang"
      />

      <main class="default-content">
        <div class="container">
          <v-steps
            v-if="showSteps"
            :isRight="isRightLangDirection"
            :steps="getSteps"
            @onClick="(_, step) => handleGoToFirstStep(step)"
          />

          <router-view />
        </div>
      </main>

      <v-footer :appName="getAppName" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import VHeader from "@/components/VHeader";
import VFooter from "@/components/VFooter";
import VSteps from "@/components/VSteps";
import VLoader from "@/components/VLoader";

export default {
  name: "App",

  watch: {
    $route(to) {
      if (to.name === "SearchInvoice") {
        this.handleClearState();
      }
    },
  },

  methods: {
    ...mapMutations("invoice", ["CLEAR_INVOICE"]),
    ...mapMutations("products", ["REMOVE_ORDER"]),
    ...mapMutations("rmaForm", ["CLEAR_FORM_DATA"]),
    ...mapMutations("steps", ["CLEAR_STEPS"]),

    ...mapActions("app", ["getLocale"]),

    handleClearState() {
      this.CLEAR_INVOICE();
      this.REMOVE_ORDER();
      this.CLEAR_FORM_DATA();
      this.CLEAR_STEPS();
    },

    handleChangeLang(lang) {
      this.$router.push({ params: { lang } });
    },

    handleGoToFirstStep(step) {
      if (step.name === "SearchInvoice") {
        this.handleClearState();
        this.$router.push("/");
      }
    },
  },

  computed: {
    ...mapGetters("app", [
      "getAppName",
      "getCurrentLang",
      "isRightLangDirection",
    ]),
    ...mapGetters("translation", {
      getLanguages: "getLanguages",
      getTranslationLoading: "getLoading",
    }),
    ...mapGetters("invoice", {
      getInvoiceLoading: "getLoading",
    }),
    ...mapGetters("rmaForm", {
      getRmaFormLoading: "getLoading",
    }),
    ...mapGetters("steps", ["getSteps", "getActiveStep"]),

    showSteps() {
      return !window.location.pathname.split("/").includes("404");
    },

    showContent() {
      return Array.isArray(this.getLanguages) && this.getLanguages.length;
    },

    showLoading() {
      return this.getTranslationLoading || this.getRmaFormLoading;
    },
  },

  created() {
    if (this.getAppName.toLowerCase() === "eptimo") {
      document.body.classList.add("eptimo");
    }
  },

  components: {
    VHeader,
    VFooter,
    VSteps,
    VLoader,
  },
};
</script>
